<template>
    <div class="digest">
        <div class="container">
            <div class="digest__header">
                <div class="digest__header--wrapper">
                    <z-caption
                        class="u-collapse--all digest__header-title"
                        weight="700"
                        tag="h1"
                        size="5xl"
                    >Развивайте <br><span class="primary-text">профессиональные навыки</span>
                    </z-caption>
                    <p class="digest__text">Корпоративный университет всё понимает про вечный аврал и&nbsp;нехватку
                        времени, поэтому и&nbsp;сделал этот дайджест: на&nbsp;его чтение вы&nbsp;потратите не&nbsp;больше
                        пяти минут, а&nbsp;узнаете какие компетенции сейчас особенно ценятся, какие курсы их&nbsp;развивают
                        и&nbsp;где вообще найти ресурсы на&nbsp;непрерывное образование.</p>
                </div>
                <div class="digest__header--card">
                    <z-caption weight="600" tag="h5" size="m" class="u-bottom-margin--2xs-important">Читать, учиться, получать
                        удовольствие от&#160;процесса и&#160;бонусы&#160;&#8212; все это в&#160;&#171;Академия.&nbsp;Дайджест&#187;!
                    </z-caption>
                    <p class="digest__header--text">Корпоративный университет всё понимает про вечный аврал и&nbsp;нехватку
                        времени, поэтому и&nbsp;сделал этот дайджест: на&nbsp;его чтение вы&nbsp;потратите не&nbsp;больше
                        пяти минут, а&nbsp;узнаете какие компетенции сейчас особенно ценятся, какие курсы их&nbsp;развивают
                        и&nbsp;где вообще найти ресурсы на&nbsp;непрерывное образование.</p>
                </div>
            </div>
            <current-article :digest="digestCurrent"></current-article>
        </div>

        <div class="digest__bg">
            <div class="digest__bg-wrapper">
                <list-article :digest="digest"></list-article>
                <digest-form></digest-form>
            </div>
        </div>
    </div>
</template>

<script>
import DigestForm from './components/DigestForm'
import CurrentArticle from './components/СurrentArticle'
import ListArticle from './components/ListArticle'

export default {
    name: 'digest',
    components: {
        DigestForm,
        CurrentArticle,
        ListArticle
    },
    data () {
        return {
            digest: {},
            digestCurrent: {}
        }
    },
    mounted () {
        if (App && App.components && App.components.digest && App.components['digest-current']) {
            this.digest = App.components.digest
            this.digestCurrent = App.components['digest-current']
        } else {
            console.error('Error: digest data is not available')
        }
    }
}
</script>

<style lang="scss">
.digest {
    $parent: &;
    background-color: $token-colors-bg-5;

    &__header-title {
        @include breakpoint (tablet) {
            @include typo-level(4XL, true);
        }
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: $token-spacers-s;
        width: 100%;
        @include margin-level(bottom, 2XL);
        @include padding-level(top, L);

        @include breakpoint (tablet) {
            gap: 48px;
        }

        @include breakpoint (mobile) {
            padding-top: $token-spacers-m;
        }

        &--wrapper {
            display: flex;
            flex-direction: column;
            max-width: 996px;
            gap: $token-spacers-2-xs;

            @include breakpoint (tablet) {
                max-width: 600px;
            }
        }

        &--card {
            display: flex;
            flex-direction: column;
            gap: $token-spacers-2-xs;
            background-color: $token-colors-white;
            padding: 40px;
            max-width: 610px;
            border-radius: 32px;

            @include breakpoint (laptop) {
                @include padding-level(top, M);
                @include padding-level(bottom, M);
                @include padding-level(left, M);
                @include padding-level(right, M);
            }

            @include breakpoint (v-tablet) {
                display: none;
            }
        }

        &--text {
            @include typo-level(XS);
        }

        @include breakpoint(tablet) {
            &--card {
                max-width: 358px;
                padding: $token-spacers-s;
            }

            &--text {
                display: none;
            }
        }

        @include breakpoint(v-tablet) {
            &--wrapper {
                max-width: 535px;
            }
        }

        @include breakpoint(mobile) {
            &--card {
                display: none;
            }
        }

        #{$parent}__header-title {
            line-height: 1.2;
        }
    }

    &__text {
        display: none;
        @include typo-level(XS);

        @include breakpoint(tablet) {
            display: block;
        }

        @include breakpoint(mobile) {
            display: none;
        }
    }

    &__bg-wrapper {
        position: relative;
        z-index: 2;
    }

    &__bg {
        border-radius: 30px;
        background: linear-gradient(34deg, #CDE4F4 10.32%, #F5F8FD 86.59%), radial-gradient(469.76% 134.51% at 100% 93.48%, #DEEDF7 0%, rgba(255, 255, 255, 0.00) 100%), #FFF;
        @include padding-level(bottom, 3XL);

        @include breakpoint (mobile) {
            background: none;
            padding-bottom: 0;
        }

        &:after {
            content: '';
            display: block;
            background-image: url("data:image/svg+xml,%3Csvg width='2675' height='3737' viewBox='0 0 2675 3737' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg filter='url(%23filter0_f_9869_2013)'%3E%3Cellipse cx='1337.42' cy='1868.49' rx='975.5' ry='1506.6' fill='%23F5F8FD' fill-opacity='0.77'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_f_9869_2013' x='0.0218811' y='-0.00497437' width='2674.8' height='3736.99' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape'/%3E%3CfeGaussianBlur stdDeviation='180.95' result='effect1_foregroundBlur_9869_2013'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            background-size: contain;
            background-position: 50% 50%;
            position: absolute;
            bottom: 0;
            right: 0;
            height: 70%;
            aspect-ratio: 1950 / 3000;
            z-index: 1;
            max-width: 100%;
            transform: translateX(20%) rotate(12deg);
            pointer-events: none;
        }
    }
}
</style>
