<template>
    <z-tabs @toggle-item="onTabChange" ref="tabs">
        <template v-slot:label>
            <z-tabs-label id="events">Предстоящие</z-tabs-label>
            <z-tabs-label id="past">Прошедшие</z-tabs-label>
        </template>
        <template v-slot:content>
            <z-tabs-content id="events" @change-tab="changeTabHandler">
                <events source="events" ref="events" :init-params="paramsUpcoming"/>
            </z-tabs-content>
            <z-tabs-content id="past">
                <events source="events-past" ref="past" :init-params="paramsPast" past/>
            </z-tabs-content>
        </template>
    </z-tabs>
</template>

<script>
import { queryString } from '@/utils/queryString'
import Events from './Events.vue'

export default {
    name: 'events-page',
    components: {
        Events
    },
    data () {
        return {
            paramsPast: {},
            paramsUpcoming: {},
            currentTab: 'tabs-events'
        }
    },
    methods: {
        onTabChange (tab) {
            this.clearParams(tab)
        },
        changeTabHandler (data) {
            if (!this.$refs.tabs) return
            this.$refs.tabs.openHandler(data)
        },
        clearParams (tab) {
            if (tab === this.currentTab) return
            this.currentTab = tab
            if (this.$refs['past'] && this.$refs['past'].$refs['filter']) {
                this.paramsPast = this.$refs['past'].$refs['filter'].params
            }
            if (this.$refs['events'] && this.$refs['events'].$refs['filter']) {
                this.paramsUpcoming = this.$refs['events'].$refs['filter'].params
            }
            if (tab === 'tabs-past') {
                queryString(this.clearEmptyParams(this.paramsPast))
            } else {
                queryString(this.clearEmptyParams(this.paramsUpcoming))
            }
        },
        clearEmptyParams (obj) {
            let params = Object.assign({}, obj)

            Object.keys(params).forEach(function (key, index) {
                if (Array.isArray(params[key])) {
                    if (!(params[key].filter(val => { return val !== null && val !== undefined && val !== '' && val !== false }).length)) delete params[key]
                } else if (!params[key]) {
                    delete params[key]
                }
            })

            return params
        },
        getParams (url = window.location) {
            let params = {}

            new URL(url).searchParams.forEach((val, key) => {
                if (key.includes('[]')) {
                    if (!params[key.replace('[]', '')]) { params[key.replace('[]', '')] = [] }
                    if (!params[key.replace('[]', '')].includes(val)) {
                        params[key.replace('[]', '')].push(this.sanitizeValue(val))
                    }
                } else {
                    params[key] = this.sanitizeValue(val)
                }
            })

            if (window.location.hash === '#tabs-past') {
                this.paramsPast = Object.assign(this.paramsPast, params)
            } else {
                this.paramsUpcoming = Object.assign(this.paramsUpcoming, params)
            }
        },
        sanitizeValue (text) {
            return text
                // htmlspecialchars
                .replace(/&/g, '&amp;')
                .replace(/</g, '&lt;')
                .replace(/>/g, '&gt;')
                .replace(/"/g, '&quot;')
                .replace(/'/g, '&#039;')
                // addslashes
                .replace(/\\/g, '\\\\')
                // eslint-disable-next-line
                .replace(/\u0008/g, '\\b')
                .replace(/\t/g, '\\t')
                .replace(/\n/g, '\\n')
                .replace(/\f/g, '\\f')
                .replace(/\r/g, '\\r')
                .replace(/'/g, '\\\'')
                .replace(/"/g, '\\"')
        }
    },
    mounted () {
        this.getParams()
        this.currentTab = window.location.hash.replace('#', '')
    }
}
</script>

<style>

</style>
