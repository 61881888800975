<template>
    <div v-if="digest && digest.years && digest.years.length" class="list-article">
        <div class="container">
            <div class="list-article__wrapper">
                <z-caption class="list-article__title u-collapse--all" weight="600" tag="h3" size="4xl">
                    Все выпуски <span>дайджеста</span>
                </z-caption>
                <div class="list-article__label">PDF</div>
            </div>
            <z-tabs :theme="theme" v-if="digest.years.length">
                <template v-slot:label>
                    <z-tabs-label
                        v-for="(year, index) in digest.years"
                        :key="index"
                        :id="String(year.year)"
                        :initialState="index === 0"
                    >
                        {{ year.year }}
                    </z-tabs-label>
                </template>
                <template v-slot:content>
                    <z-tabs-content
                        v-for="(year, index) in digest.years"
                        :key="index"
                        :id="String(year.year)">
                        <div class="list-article__grid">
                            <a
                                v-for="(item, index) in visibleItems(year)"
                                :key="index"
                                :href="item.file.link"
                                target="_blank"
                                class="list-article__item article">
                                <div class="list-article__img-wrapper img-wrapper">
                                    <img
                                        :src="item.imageArchive.link"
                                        alt="Photo article"
                                        class="article__image hide-me--mobile"
                                    />
                                    <img
                                        :src="item.imageMobile.link || item.imageArchive.link"
                                        alt="Photo article"
                                        class="article__image hide-me--default show-me--mobile"
                                    />
                                </div>
                                <z-caption class="article__title" weight="600" tag="h4" size="3xl">
                                    <span v-html="item.title"></span>
                                </z-caption>
                                <p class="article__text" v-html="item.previewText"></p>
                            </a>
                            <div v-if="year.items.length > visibleItemCount(year)" class="list-article__load-more">
                                <z-button kind="bordered" @click="loadMoreItems(year)">Смотреть ещё</z-button>
                            </div>
                        </div>
                    </z-tabs-content>
                </template>
            </z-tabs>
        </div>
    </div>
</template>

<script>
export default {
    name: 'list-article',
    props: {
        digest: {
            type: Object,
            default: () => ({ years: [] })
        }
    },
    data () {
        return {
            theme: this.getTheme(),
            visibleYears: this.getInitialYears(),
            itemsToShow: 2,
            itemCountPerYear: 2
        }
    },
    methods: {
        updateTheme () {
            this.theme = this.getTheme()
        },
        getTheme () {
            return window.innerWidth < 1360 ? 'light' : 'increase'
        },
        getInitialYears () {
            if (this.digest && this.digest.years) {
                return window.innerWidth < 600 ? this.digest.years.slice(0, 2) : this.digest.years
            }
            return []
        },
        visibleItems (year) {
            if (window.innerWidth >= 600) {
                return year.items
            }
            return year.items.slice(0, this.itemCountPerYear)
        },
        visibleItemCount (year) {
            return Math.min(this.itemCountPerYear, year.items.length)
        },
        loadMoreItems (year) {
            this.itemCountPerYear += 2
        },
        handleResize () {
            this.theme = this.getTheme()
            this.visibleYears = this.getInitialYears()
        }
    },
    mounted () {
        window.addEventListener('resize', this.handleResize)
    },
    beforeDestroy () {
        window.removeEventListener('resize', this.handleResize)
    }
}
</script>

<style scoped>
.list-article__load-more {
    text-align: center;
    margin-top: 20px;
}
</style>

<style lang="scss">
.list-article {
    padding-bottom: 140px;
    @include padding-level(top, 2XL);

    @include breakpoint (laptop) {
        @include padding-level(bottom, 3XL);
    }

    &__wrapper {
        display: flex;
        align-items: center;
        gap: $token-spacers-xs;
        @include padding-level(bottom, 3XL);

        @include breakpoint (mobile) {
            gap: 8px;
        }
    }

    &__label {
        padding: $token-spacers-3-xs $token-spacers-xs;
        @include padding-level(top, 3XS);
        @include padding-level(bottom, 3XS);
        @include padding-level(left, XS);
        @include padding-level(right, XS);

        border-radius: 40px;
        border: 1px solid $token-colors-primary;
        font-size: var(--fontSizeS);
        color: $token-colors-primary;
        font-weight: 500;
    }

    &__title {
        span {
            color: $token-colors-primary;
        }
    }

    &__grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 32px 38px;
        margin-left: -40px;
        margin-right: -40px;

        @include breakpoint(laptop) {
            gap: 32px 24px;
            @include margin-level(left, XS, true);
            @include margin-level(right, XS, true);
        }

        @include breakpoint(tablet) {
            gap: 48px 40px;
            margin-left: 0;
            margin-right: 0;
            grid-template-columns: repeat(2, 1fr);
        }

        @include breakpoint(tablet) {
            gap: 48px 28px;
        }

        @include breakpoint(mobile) {
            grid-template-columns: repeat(1, 1fr);
            gap: 48px;
        }
    }

    &__item {
        &:hover {
            box-shadow: 0 8px 8px rgba(0, 32, 51, 0.04), 0 12px 28px #78a5db;

            .article__title {
                color: $token-colors-link-dark-hover;
            }

            @include breakpoint(tablet) {
                box-shadow: none;
            }
        }
    }

    &__img-wrapper {
        display: flex;
    }

    @include breakpoint(tablet) {

        &__wrapper {
            padding-bottom: $token-spacers-m;
        }
    }
    @include breakpoint(v-tablet) {
        &__wrapper {
            padding-bottom: $token-spacers-s;
        }
    }
    @include breakpoint(mobile) {
        &__label {
            padding: 8px 13px;
            font-size: $token-typo-xs-desktop-size;
        }

    }

    &__load-more {
        display: none;
        @include breakpoint(mobile) {
            display: block;
        }
    }
}

.article {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 40px;
    @include padding-level(bottom, XL);
    border-radius: 32px;
    text-decoration: none;

    @include breakpoint(laptop) {
        @include padding-level(top, XS);
        @include padding-level(left, XS);
        @include padding-level(right, XS);
    }

    @include breakpoint (mobile) {
        background-repeat: 24px;
    }

    &__title {
        display: -webkit-box;
        line-clamp: 3;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        color: $token-colors-link-dark-default;
        margin: 0 !important;
        text-decoration: none;
        transition: color 0.2s ease-in-out;
        min-height: 3lh;

        @include breakpoint (mobile) {

        }
    }

    &__image {
        // min-height: 328px;
        width: 100%;
        height: auto;
        border-radius: 32px;
        object-fit: cover;
    }

    &__text {
        @include typo-level(M);
        color: #14161A;
        text-decoration: none;
        line-clamp: 4;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        display: -webkit-box;

        @include breakpoint(tablet) {
            display: none;
        }
    }

    @include breakpoint(tablet) {
        padding: 0;

        // &__image {
        //     min-height: 290px;
        // }
    }

    @include breakpoint(mobile) {
        &__image {
            // min-height: 416px;
            border-radius: 16px;
        }
    }
}
</style>
