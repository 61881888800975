<template>
    <nav
        class="z-breadcrumbs"
        :class="{[`z-breadcrumbs--${theme}`]: theme }"
    >
        <ul
            class="z-breadcrumbs__list"
            itemscope=""
            itemtype="http://schema.org/BreadcrumbList"
        >
            <li
                class="z-breadcrumbs__item"
                v-for="(item, index) in data"
                :key="index"
                itemprop="itemListElement"
                itemscope=""
                itemtype="http://schema.org/ListItem"
            >
                <component
                    :is="isCurrent(item.url) ? 'span' : 'z-link'"
                    :theme="!isCurrent(item.url) ? 'light' : false"
                    class="z-breadcrumbs__link"
                    style="display: inline;"
                    :href="item.url"
                    :title="item.name"
                    itemscope=""
                    itemtype="http://schema.org/Thing"
                    itemprop="item"
                    :underlined="false"
                >
                    <span v-html="item.name"></span>
                </component>
            </li>
        </ul>
    </nav>
</template>

<script>
export default {
    name: 'z-breadcrumbs',
    data () {
        return {
            pathname: window.location.pathname,
            data: this.$root.app.components.breadcrumbs
        }
    },
    props: {
        theme: {
            type: String,
            validator: prop => ['light'].includes(prop)
        }
    },
    methods: {
        isCurrent (link) {
            return this.pathname === link
        }
    }
}

</script>

<style lang="scss" src="./index.scss"></style>
