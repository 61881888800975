<template>
    <div :class="[
        'slider',
        `slider--controls-${this.controlsPlacement}`,
        {'slider--video': isVideo}
    ]">
        <div class="swiper-container slider__container" ref="slider">
            <div class="swiper-wrapper">
                <slot></slot>
            </div>
        </div>
        <div class="slider__controls" v-if="this.$slots.default && this.$slots.default.length > 1">
            <button v-if="buttons" class="slider__nav-btn slider__nav-btn--prev" ref="prev">
                <z-icon
                    name="buttons/arrow_left"
                />
            </button>
            <div v-if="pagination" class="slider__pagination" :class="{'slider__pagination--dark': isPaginationDark}" ref="pagination"></div>
            <button v-if="buttons" class="slider__nav-btn slider__nav-btn--next" ref="next">
                <z-icon
                    name="buttons/arrow_right"
                />
            </button>
        </div>
    </div>
</template>

<script>
import Swiper from 'swiper/dist/js/swiper.js'
import { mixinDevice } from '@/utils/mixin'
export default {
    name: 'slider',
    mixins: [mixinDevice],
    data () {
        return {
            slider: undefined,
            activeIndex: this.startIndex
        }
    },
    props: {
        lastEmpty: {
            type: Boolean,
            default: false
        },
        effect: {
            type: String,
            default: 'slide'
        },
        buttons: {
            type: Boolean,
            default: true
        },
        pagination: {
            type: Boolean,
            default: true
        },
        autoplay: {
            type: Boolean,
            default: false
        },
        loop: {
            type: Boolean,
            default: false
        },
        speed: {
            type: String,
            default: '5000'
        },
        slidesPerView: {
            type: [Number, String],
            default: 'auto'
        },
        controlsPlacement: {
            type: String,
            default: 'left',
            validator: prop => ['center', 'left'].includes(prop)
        },
        spaceBetween: {
            type: Number,
            default: 0
        },
        isVideo: {
            type: Boolean,
            default: false
        },
        isPaginationDark: {
            type: Boolean,
            default: false
        },
        startIndex: {
            type: Number,
            default: 0
        }
    },
    methods: {
        init () {
            const _this = this

            let options = {
                allowTouchMove: true,
                observer: true,
                observeParents: true,
                loop: this.loop,
                centeredSlides: true,
                navigation: {
                    prevEl: this.$refs.prev,
                    nextEl: this.$refs.next
                },
                spaceBetween: this.spaceBetween,
                effect: this.effect,
                pagination: {
                    clickable: true,
                    el: this.$refs.pagination
                },
                initialSlide: this.startIndex,
                on: {
                    slideChange () {
                        _this.activeIndex = this.activeIndex
                    }
                }
            }

            if (this.slidesPerView !== 1) {
                options.slidesPerView = this.slidesPerView
            }

            if (this.autoplay) {
                options.autoplay = {
                    delay: this.speed
                }
            }

            this.slider = new Swiper(this.$refs.slider, options)
            if (_this.lastEmpty) {
                const lastItem = [..._this.$refs.pagination.children].pop()
                _this.$refs.pagination.removeChild(lastItem)
                this.slider.on('slideChange', function () {
                    if ((this.activeIndex + 1) * parseInt(this.params.slidesPerView) >= this.slides.length) {
                        _this.$refs.next.classList.add('swiper-button-disabled')
                    } else if ((_this.device === 'v-tablet' || _this.device === 'mobile') && (this.activeIndex + 1) * parseInt(this.params.slidesPerView) + 1 >= this.slides.length) {
                        _this.$refs.next.classList.add('swiper-button-disabled')
                    } else {
                        _this.$refs.next.classList.remove('swiper-button-disabled')
                    }
                })
            }
        }
    },
    mounted () {
        this.init()
    }
}
</script>

<style lang="scss">
.slider {
    $parent: &;
    position: relative;
    width: 100%;

    .swiper-container {
        padding: 4vw 0;

        @include breakpoint(tablet) {
            padding: calc(50vw*0.65*0.15) 0;
        }

        @include breakpoint(mobile) {
            padding: calc(50vw*0.85*0.15) 0;
        }
    }

    @include breakpoint (mobile) {
        width: 100%;
    }

    &__controls {
        display: flex;
        position: absolute;
        margin-top: 0;
        bottom: auto;
        width: 33.333%;
        left: 50%;
        transform: translateX(-50%);
        height: 0;
        padding-top: calc(33.333% * 0.65); //тут берется 33% ширины нашего слайда и умножается на 65% это соотношение сторон ширины и высота
        top: 4vw;

        @include breakpoint(tablet) {
            width: 100%;
            left: 0;
            transform: translateX(0) scale(1);
            height: auto;
            padding-top: 0;
            top: calc(50vw*0.65*0.25 + 50vw*0.35*1.25); // 50% ширины слайда умножается на 65% соотношение сторон умножается на scale 0.25 плюс 50% ширины умножается на (значение для позиционирования нашей пагинации) и умножается на scale 1.25
        }

        @include breakpoint(v-tablet) {
            top: calc(50vw*0.65*0.25 + 50vw*0.53*1.25);

        }

        @include breakpoint(mobile) {
            top: calc(50vw*0.65*0.25 + 50vw*0.58*1.25);
        }
    }

    &--controls-center {
        #{$parent}__controls {
            justify-content: center;
            z-index: 5;
        }
    }

    &__nav-btn {
        pointer-events: all;
        transition: all $transition;
        cursor: pointer;
        outline: none;
        border-radius: 50%;
        width: 80px;
        height: 80px;
        background-color: $token-colors-white;
        border: 1px solid $token-colors-white;

        svg {
            width: 24px;
            height: 24px;

            @include breakpoint (mobile) {
                width: 20px;
                height: 20px;
            }
        }

        path {
            fill: $token-colors-primary;
        }

        &--prev {
            position: absolute;
            left: 0;
            bottom: -12px;

            @include margin-level(right, S);

            &:hover {
                transform: translateX(-2px);
            }
        }

        &--next {
            position: absolute;
            right: 0;
            bottom: -12px;

            @include margin-level(left, S);

            &:hover {
                transform: translateX(2px);
            }
        }

        &--prev,
        &--next {
            @include breakpoint(tablet) {
                position: relative;
                bottom: 0;
            }

            @include breakpoint(v-tablet) {
                display: none;
            }
        }

        &.swiper-button-disabled {
            background-color: transparent;
            pointer-events: none;
            path {
                fill: $token-colors-white;
                opacity: 0.9;
            }
        }
    }

    &__pagination {
        display: flex;
        align-items: center;
        position: absolute;
        bottom: 24px;

        @include breakpoint(tablet) {
            position: relative;
            bottom: 0;
        }

        .swiper-pagination-bullet {
            border-radius: 4px;
            background: $token-colors-white;
            width: 8px;
            height: 8px;
            transition: all $transition;

            @include breakpoint(v-tablet) {
                background: $token-colors-primary;
            }

            &:not(:last-child) {
                margin-right: 16px;
            }

            &-active {
                transform: scale(1.5);
                width: 8px;
            }
        }

        &--dark {
            .swiper-pagination-bullet {
                background: $token-colors-button-primary-bg-active;
            }
        }

    }

    .swiper-slide {
        max-width: 33.33%;
        transform: scale(1);
        position: relative;
        z-index: 1;
        transition: transform 400ms ease;

        &__content,
        &__wrapper {
            height: 100%;

            p {
                display: none;
            }
        }

        .z-image {
            height: 0;
            padding-top: 65%;
            background-color: #eee;
            border-radius: 20px;
            overflow: hidden;
            display: block;

            &__img {
                display: block;
                height: 103%;
                width: 102%;
                object-fit: contain;
                position: absolute;
                left: -1%;
                top: -1%;
                max-width: none;
                margin-bottom: 50px;
            }
        }
        &.swiper-slide-active {
            transform: scale(1.25);
            z-index: 2;

            p {
                font-weight: 400;
                // font-size: 20px;
                font-size: var(--fontSizeXl);
                line-height: 130%;
                color: #4E4E4E;
                display: block;
                width: 100%;
                text-align: center;
                margin-top: $token-spacers-xs;
                margin-bottom: 0;

                @include breakpoint(v-tablet) {
                    // font-size: 18px;
                    line-height: 140%;
                    width: 100vw;
                    transform: translateX(-25%);
                    padding: 0 25%;
                }

                @include breakpoint(mobile) {
                    // font-size: 16px;
                    line-height: 130%;
                }
            }
        }

        @include breakpoint(tablet) {
            max-width: 50%;
        }
    }

    &--video {
        #{$parent}__controls {
            width: auto;
            height: auto;
            position: relative;
            padding-top: 0;
            top: 0;
            left: 0;
            bottom: 0;
            transform: translate(0);

            @include breakpoint(v-tablet) {
                margin-top: $token-spacers-xs;
            }
        }

        .slider__nav-btn--prev {
            bottom: auto;
            position: relative;
        }

        .slider__pagination {
            bottom: auto;
            position: relative;
        }

        .slider__nav-btn--next {
            bottom: auto;
            position: relative;
        }

        .swiper-button-disabled {
            background-color: transparent;
            pointer-events: none;
            border-color: $token-colors-primary;
            path {
                fill: $token-colors-primary;
                opacity: 0.9;
            }
        }

        .swiper-pagination-bullet {
            background-color: $token-colors-primary;
        }

        .swiper-slide {
            pointer-events: none;
        }

        .swiper-slide-active {
            pointer-events: all;
        }
    }
}
</style>
