<template>
    <div class="container">
        <div class="events-detail-hero-block" :style="`background-image: url('${previewImage}')`">
            <z-breadcrumbs theme="light"></z-breadcrumbs>
            <div class="events-detail-hero-block__wrapper">
                <div class="events-detail-hero-block__left">
                    <z-caption
                        tag="h1"
                        size="5xl"
                        weight="600"
                        class="events-detail-hero-block__title"
                    >
                        {{ data.item.title }}
                    </z-caption>
                    <div class="events-detail-hero-block__bottom">
                        <z-button
                            size="l"
                            v-if="!data.item.past && data.item.formatRegistration.xmlId !== 'not'"
                            tag="a"
                            href="#event-registration-form"
                            target="_self"
                            kind="secondary">
                            Зарегистрироваться
                        </z-button>
                        <z-button
                            size="l"
                            v-if="!data.item.past && data.item.formatRegistration.xmlId === 'not' && data.item.formatEvent.xmlId === 'online' && data.item.translationLink.value"
                            tag="a"
                            href="#event-detail__video"
                            target="_self"
                            kind="secondary">
                            Смотреть онлайн
                        </z-button>
                        <z-button
                            tag="a"
                            href="#event-detail__video"
                            size="l"
                            v-if="data.item.past && data.item.formatEvent.xmlId === 'online' && data.item.vkLink.value"
                            kind="secondary">
                            Смотреть запись
                        </z-button>
                        <div class="events-detail-hero-block__tools">
                            <z-share :services="shareServices">
                                <z-icon name="round/share" width="72" height="72"/>
                            </z-share>
                        </div>
                    </div>
                </div>
                <card-for-hero :data="data.item" class="hide-me--v-tablet"></card-for-hero>
            </div>
        </div>
        <card-for-hero :data="data.item" class="hide-me--default show-me--v-tablet"></card-for-hero>
    </div>
</template>

<script>
import CardForHero from './CardForHero'

export default {
    name: 'events-detail-hero-block',
    props: {
        data: Object
    },
    components: {
        CardForHero
    },
    data () {
        return {
            shareServices: ['vkontakte', 'odnoklassniki', 'telegram', 'viber', 'whatsapp', 'moimir', 'lj']
        }
    },
    computed: {
        previewImage () {
            if (this.data.item.previewImage && this.data.item.previewImage.link) {
                return this.data.item.previewImage.link
            }
            return ''
        }
    }
}
</script>

<style lang="scss">
.events-detail-hero-block {
    border-radius: 20px;
    padding: 74px;
    background-color: $token-colors-primary;
    background-image: url("/images/territory/hero-image.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-bottom: $token-spacers-2-xl;
    position: relative;

    &:before {
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(0deg, rgba(25, 113, 207, 0.39) 0%, rgba(25, 113, 207, 0.39) 100%);
        position: absolute;
        border-radius: 20px;
    }

    @include breakpoint(tablet) {
        padding: $token-spacers-m;
    }

    @include breakpoint(v-tablet) {
        margin-bottom: $token-spacers-xs;
    }

    @include breakpoint(mobile) {
        padding: 20px;
    }

    .z-breadcrumbs {
        margin-bottom: $token-spacers-xs;

        .z-breadcrumbs__item:not(.is-current) .z-breadcrumbs__link {
            color: $token-colors-white;
        }

        .z-breadcrumbs__item:not(.is-current):after {
            color: $token-colors-black-40;
        }
    }

    &__wrapper {
        position: relative;
        display: flex;
        justify-content: space-between;
        gap: $token-spacers-2-xl;

        @include breakpoint(tablet) {
            flex-direction: column;
        }
    }

    &__bottom {
        display: flex;
        align-items: center;
    }

    &__tools {
        margin-left: $token-spacers-2-xs;
        display: flex;

        @include breakpoint(tablet) {
            .share-tooltip .z-icon {
                width: 45px !important;
                height: 45px !important;
            }

            .share-tooltip .z-share__title .z-icon {
                width: 24px !important;
            }
        }
    }

    .card-for-hero {
        @include breakpoint(v-tablet) {
            margin-bottom: $token-spacers-l;
        }
    }

    &__title {
        line-height: 1.2;
        color: $token-colors-white;
        margin-bottom: $token-spacers-m;
    }
}
</style>
