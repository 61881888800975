<template>
    <div class="filter">
        <div class="filter__row">
            <div class="filter__wrapper">
                <z-select-multi
                    class="filter__item"
                    :data="data.type"
                    v-model="params.type"
                    placeholder="Тип мероприятия"
                    @change="changeFilter"
                    is-filter
                ></z-select-multi>
                <z-select
                    class="filter__item"
                    :data="data.format"
                    v-model="params.format"
                    placeholder="Формат"
                    @change="changeFilter"
                    is-filter
                ></z-select>
                <div class="filter-datapicker filter__item">
                    <z-date-picker
                        class="filter__item"
                        placeholder="с"
                        is-filter
                        v-model="params.from"
                        @change="changeFilter"
                    />
                    <span class="hide-me--mobile">—</span>
                    <z-date-picker
                        class="filter__item"
                        placeholder="по"
                        is-filter
                        v-model="params.to"
                        @change="changeFilter"
                    />
                </div>
            </div>
            <z-link
                class="filter__clear"
                href="javacript:void(0)"
                icon="close-round"
                icon-position="left"
                @click="onClear"
                :underlined="false"
                size="xl"
            ></z-link>
        </div>
        <div class="filter__tags" v-if="filteredTags.length > 0">
            <button
                class="filter__button"
                @click="clearTags"
                :class="{'filter__button--selected ' : params.tags && params.tags.length === 0}"
            >
                Все
            </button>
            <z-tag
                v-for="(item, index) in filteredTags"
                :key="index"
                :xmlId="item.xmlId"
                button
                is-marker
                :is-active="isTagActive(item.id)"
                v-html="item.text"
                @click="toogleTag(item.id)">
            </z-tag>
        </div>
    </div>
</template>

<script>
import { queryString } from '@/utils/queryString'

export default {
    name: 'events-filter',
    props: {
        data: Object,
        initParams: Object
    },
    data () {
        return {
            params: {
                from: '',
                to: '',
                type: [],
                format: '',
                tags: []
            }
        }
    },
    computed: {
        filteredTags () {
            return this.data.tags.filter(it => !it.disabled) || []
        }
    },
    methods: {
        onClear () {
            this.clearParams()
            this.changeFilter()
        },
        clearParams () {
            this.params = {
                from: '',
                to: '',
                type: [],
                format: '',
                tags: [],
                page: 1
            }
        },
        clearTags () {
            this.params.tags = []
            this.changeFilter()
        },
        changeFilter () {
            this.$emit('change-filter', this.params)
            const params = {}
            this.params.type && (params.type = this.params.type)
            this.params.tags && (params.tags = this.params.tags)
            this.params.format && (params.format = this.params.format)
            this.params.from && (params.from = this.params.from)
            this.params.to && (params.to = this.params.to)

            queryString(params)
        },
        toogleTag (tag) {
            if (this.params.tags.includes(tag)) {
                this.params.tags = this.params.tags.filter(item => item !== tag)
            } else {
                this.params.tags.push(tag)
            }
            this.changeFilter()
        },
        isTagActive (tagId) {
            return this.params.tags.includes(tagId)
        }
    },
    mounted () {
        this.$nextTick(() => {
            this.params = { ...this.params, ...this.initParams }
        })
    }
}
</script>

<style lang="scss">
.filter {
    display: flex;
    flex-direction: column;
    gap: $token-spacers-s;

    &-datapicker {
        display: flex;
        align-items: center;

        @include breakpoint (mobile) {
            flex-direction: column;

            > * + * {
                padding-left: 0;
                padding-top: $token-spacers-s;

                @include breakpoint (mobile) {
                    padding-top: $token-spacers-2-xs;
                }
            }

            .mx-datepicker {
                min-width: 160px;
            }
        }

        span {
            padding: 0 4px;
        }
    }

    &__row {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: $token-spacers-2-xs;

        @include breakpoint (tablet) {
            flex-direction: column;
        }
    }

    &__wrapper {
        display: flex;
        align-items: center;
        position: relative;
        gap: $token-spacers-2-xs;
        width: 100%;

        @include breakpoint (tablet) {
            flex-direction: column;
            gap: $token-spacers-s;
        }

        @include breakpoint (mobile) {
            gap: $token-spacers-2-xs;
        }
    }

    .filter__clear {
        background-image: none;
        position: relative;
        display: block;

        @include breakpoint (tablet) {
            align-self: end;
        }

        .z-link__icon {
            width: 28px;
            height: 28px;

            @include breakpoint(tablet) {
                width: 24px;
                height: 24px;
            }
        }
    }

    &__item {
        width: 100%;

        &.z-date-picker {
            max-width: 200px;

            @include breakpoint (tablet) {
                max-width: 100%;
            }
        }
    }

    &__tags {
        display: flex;
        gap: $token-spacers-2-xs;
        align-items: center;
        flex-wrap: wrap;
    }

    &__button {
        border: none;
        border-radius: 40px;
        background: $token-colors-white;
        font-size: 16px;
        font-weight: 600;
        padding: 12px $token-spacers-xs;
        color: $token-colors-black-80;
        line-height: 1.2;

        &:hover {
            opacity: 0.7;
        }

        &--selected {
            background: #4781AD;
            color: $token-colors-white;
        }
    }
}
</style>
