<template>
    <div class="layout" :class="`layout--${type}`">
        <div v-if="!!this.$slots['top']" class="layout__top">
            <slot name="top" />
        </div>
        <expanders />
        <div
            class="layout__container"
            :class="[{ 'layout__container--filled': type !== 'wide' },]">
            <div :class="[{ 'container': type !== 'wide' }]">
                <div class="layout__content">
                    <slot name="content" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Expanders from '../Expanders/Expanders.vue'

export default {
    name: 'layout',
    components: {
        Expanders
    },
    props: {
        type: String,
        default: 'one-column',
        validator: prop => ['wide', 'one-column'].includes(prop)
    }
}
</script>

<style lang="scss">

.layout {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;

    &__container {
        flex-grow: 1;
        overflow: hidden;
        border-top-left-radius: $borderRadius;
        border-top-right-radius: $borderRadius;
        z-index: 2;
        position: relative;

        @include breakpoint(mobile) {
            border-top-left-radius: $token-spacers-xs;
            border-top-right-radius: $token-spacers-xs;
        }

        &:after {
            content: '';
            background: $token-colors-white;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: $borderRadius;
            display: block;
            border-top-left-radius: $borderRadius;
            border-top-right-radius: $borderRadius;
        }

        &--filled {
            background-color: $token-colors-bg-5;
        }
    }

    &__top {
        flex-shrink: 0;
        flex-grow: 0;

        + .layout__container {
            margin-top: -30px; //компенсация скругления
        }
    }

    &__content {
        width: 100%;
        padding-bottom: calc(#{$token-spacers-2-xl} + #{$borderRadius});
        @include padding-level(top, 2XL);

        @include breakpoint (mobile) {
            padding-bottom: calc(#{$token-spacers-m} + #{$borderRadius});
        }
    }

    &--wide {
        .layout__content {
            padding-top: 0;
            padding-bottom: 0;
        }
    }
}
</style>
