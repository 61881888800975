<template>
    <div class="events-detail">
        <events-detail-hero-block :data="data"/>
        <section class="events-detail__section--first container">
            <div class="col-default-10 col-default-offset-1 col-v-tablet-12 col-v-tablet-offset-0">
                <template v-if="data.item.additionalInfo">
                    <vue-raw :raw="data.item.additionalInfo.value" class="u-bottom-margin--3xl"></vue-raw>
                </template>
                <template v-if="data.item.materials && data.item.materials.length">
                    <z-caption tag="h3" size="4xl" weight="600">Материалы</z-caption>
                    <events-detail-materials :data="data.item.materials" class="u-bottom-margin--3xl"/>
                </template>
                <template v-if="data.item.speaker && data.item.speaker.length">
                    <z-caption tag="h3" size="4xl" weight="600">Спикеры</z-caption>
                </template>
            </div>
            <events-detail-speaker
                v-if="data.item.speaker && data.item.speaker.length"
                :data="data.item.speaker"
                class="col-default-offset-1 col-v-tablet-offset-0 u-bottom-margin--3xl"
            />
            <div class="col-default-10 col-default-offset-1 col-v-tablet-12 col-v-tablet-offset-0">
                <template
                    v-if="data.item.addressFull && data.item.addressFull.length && data.item.formatEvent.xmlId === 'ofline'">
                    <z-caption tag="h3" size="4xl" weigth="600">Адреса проведения</z-caption>
                    <events-detail-address :data="data.item.addressFull" class="u-bottom-margin--3xl"/>
                </template>
                <template v-if="showVideo">
                    <div id="event-detail__video">
                        <z-caption tag="h3" size="4xl" weight="600">Смотреть
                            <span>{{ data.item.past ? 'запись' : 'онлайн' }}</span>
                        </z-caption>
                        <z-figure video>
                            <iframe
                                width="100%"
                                height="100%"
                                :src="data.item.past ? data.item.vkLink.value : data.item.translationLink.value"
                                frameborder="0"
                                allowfullscreen>
                            </iframe>
                        </z-figure>
                    </div>
                </template>
            </div>
        </section>

        <section :class="{'events-detail__section--second--pagination' : secondSection}"
                 class="events-detail__section--second container"
        >
            <template
                v-if="data.item.formatEvent.xmlId === 'ofline' && data.item.detailImage && data.item.detailImage.link">
                <z-image class="events-detail__photo" :src="data.item.detailImage.link"></z-image>
            </template>
            <div class="col-default-10 col-default-offset-1 col-v-tablet-12 col-v-tablet-offset-0">
                <template v-if="data.item.formatRegistration.xmlId !== 'not' && !data.item.past">
                    <div ref="form" id="event-registration-form" class="u-bottom-margin--3xl">
                        <z-caption tag="h3" size="4xl" weight="600">Зарегистрироваться на&nbsp;мероприятие</z-caption>
                        <event-registration-form
                            :id="data.item.id"
                            :show-event-address="Boolean(data.item.showEventAddress.value)"
                            :event-address="data.item.eventAddress"
                        />
                    </div>
                </template>
            </div>
            <template v-if="data.item.mediagallery.length">
                <div class="col-default-10 col-default-offset-1 col-v-tablet-12 col-v-tablet-offset-0">
                    <z-caption tag="h3" size="4xl" weight="600">Фотографии c <span>мероприятия</span></z-caption>
                </div>
                <div class="col-default-12 col-v-tablet-12 col-v-tablet-offset-0">
                    <slider :buttons="true" controls-placement="center" isPaginationDark>
                        <slide v-for="(item, indexM) in data.item.mediagallery" :key="indexM">
                            <z-figure :image="item.link"/>
                        </slide>
                    </slider>
                </div>
            </template>
            <div class="col-default-10 col-default-offset-1 col-v-tablet-12 col-v-tablet-offset-0">
                <div v-if="data.nav.prev || data.nav.next">
                    <page-changer
                        :data="{
                                next: {
                                    name: '',
                                    url: data.nav.next ?  data.nav.next.url : ''
                                },
                                prev: {
                                name: '',
                                url: data.nav.prev ? data.nav.prev.url : ''
                            },
                            ['back-link']: data.nav['back-link']
                        }"
                        buttonText="Все мероприятия"
                    />
                </div>
            </div>
        </section>

        <section class="events-detail__section--third container">
            <template v-if="similarEvents && similarEvents.length">
                <z-caption tag="h3" size="4xl" weigth="600">Похожие мероприятия</z-caption>
                <div class="events-detail__events">
                    <event-card-new
                        v-for="(event, index) in similarEvents.slice(0, 3)"
                        :key="index"
                        tag="a"
                        :data="event"/>
                </div>
            </template>
            <template v-if="data.item.linkedNews && data.item.linkedNews.length">
                <z-caption tag="h3" size="4xl" weigth="600">Новости по&nbsp;теме</z-caption>
                <news-list :data="data.item.linkedNews"/>
            </template>
        </section>
    </div>
</template>

<script>
import EventCardNew from './components/EventCardNew'
import EventsDetailAddress from './components/EventsDetailAddress'
import EventsDetailSpeaker from './components/EventsDetailSpeaker'
import EventsDetailHeroBlock from './components/EventsDetailHeroBlock'
import EventsDetailMaterials from './components/EventsDetailMaterials'

export default {
    name: 'events-detail',
    components: {
        EventsDetailHeroBlock,
        EventsDetailSpeaker,
        EventCardNew,
        EventsDetailAddress,
        EventsDetailMaterials
    },
    data () {
        return {
            data: this.$root.app.components['events-detail'],
            similarEvents: this.$root.app.components['similar-events'].items
        }
    },
    methods: {
        scrollToForm (obj) {
            if (obj) return false
            if (this.$refs.form) {
                this.$refs.form.scrollIntoView({block: 'start', behavior: 'smooth'})
            }
        }
    },
    computed: {
        showVideo () {
            return this.data.item.formatEvent.xmlId === 'online' &&
                (this.data.item.vkLink.value && this.data.item.past) ||
                (this.data.item.translationLink.value && !this.data.item.past)
        },

        secondSection () {
            return !(this.data.item.formatEvent.xmlId === 'ofline' && this.data.item.detailImage && this.data.item.detailImage.link) &&
                !(this.data.item.formatRegistration.xmlId === 'site') &&
                this.data.item.mediagallery.length === 0
        }
    }
}
</script>

<style lang="scss">
html {
    scroll-behavior: smooth;
}

.events-detail {
    background: $token-colors-bg-5;
    @include padding-level(top, 2XL);

    @include breakpoint(mobile) {
        .remind .z-icon {
            width: 45px !important;
            height: 45px !important;
        }
    }

    .z-caption {
        > span {
            color: $token-colors-primary;
        }

        &.z-caption--size-4xl {
            @include margin-level(bottom, L);

            @include breakpoint(v-tablet) {
                @include margin-level(bottom, M);
            }
        }
    }

    .z-figure__video iframe {
        min-height: 684px;

        @include breakpoint(v-tablet) {
            min-height: 500px;
        }

        @include breakpoint(mobile) {
            min-height: 400px;
        }
    }

    &__photo {
        @include padding-level(bottom, 3XL);

        &.z-image {
            width: 100%;

            .z-image__img {
                border-radius: 32px;
                width: 100%;
            }
        }
    }

    .page-changer {
        margin-top: $token-spacers-xl;

        @include breakpoint(v-tablet) {
            flex-wrap: nowrap;
        }
    }

    &__events {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 40px;
        @include padding-level(bottom, 3XL);

        @include breakpoint(tablet) {
            grid-template-columns: repeat(2, 1fr);

            .event-card-new:nth-child(2n) {
                display: none;
            }
        }

        @include breakpoint(mobile) {
            grid-template-columns: repeat(1, 1fr);
        }
    }

    &__section {
        &--first {
            // margin-bottom: $token-spacers-3-xl;
            margin-bottom: 144px;

            @include breakpoint(laptop) {
                @include margin-level(bottom, 4XL);
            }
        }

        &--second {
            padding-top: $token-spacers-3-xl;
            // background: linear-gradient(62deg, rgba(205, 228, 244, 0.26) -23.4%, #F5F8FD 103.37%);
            border-radius: $borderRadius $borderRadius 0 0;
            padding-bottom: $token-spacers-4-xl;

            @include breakpoint(laptop) {
                @include padding-level(top, 3XL);
                @include padding-level(bottom, 4XL);
            }

            &--pagination {
                padding-top: 0;
                padding-bottom: 144px;

                @include breakpoint(laptop) {
                    @include padding-level(bottom, 4XL);
                }

                .page-changer {
                    margin-top: 0;
                }
            }

            .slider {
                @include padding-level(bottom, M);

                &__controls {
                    top: 20px;
                    @include padding-level(top, M);
                    position: relative;
                }

                &__nav-btn {
                    border-color: $token-colors-link-active;
                }

                .z-icon.z-fill path {
                    fill: $token-colors-link-active;
                }
            }
        }

        &--third {
            padding-top: $token-spacers-3-xl;
            background: linear-gradient(60deg, #CDE4F4 -6.25%, #EFF6FB 102.93%);
            border-radius: $borderRadius $borderRadius 0 0;
            padding-bottom: $token-spacers-4-xl;

            @include breakpoint(laptop) {
                @include padding-level(top, 3XL);
                @include padding-level(bottom, 4XL);
            }

            .news-card {
                &:first-child, &:nth-child(2) {
                    padding-top: 0;
                }

                @include breakpoint(tablet) {
                    &:not(:first-child) {
                        @include padding-level(top, M);
                    }
                }
            }
        }
    }
}
</style>
